<template>
  <div :style="cssVariablesBinding">
    <Theme5BaseTheHeader />

    <main class="n-main-container">
      <slot />
    </main>

    <Theme5BaseTheFooter />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import Theme5BaseFooterOne from '~/components/theme4/base/footer/FooterOne.vue';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme5 from '~/components/theme5/AvailableComponentsForTheme5.vue';

export default defineNuxtComponent({
  name: 'Theme5',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme5, Theme5BaseFooterOne },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  head(context) {
    if (process.server && context.ssrContext) {
      const websiteStore = useWebsiteStore();

      return {
        link: [
          {
            href: `/theme5/css/${websiteStore.companyKey}.css`,
            rel: 'stylesheet',
            hid: `css/${websiteStore.companyKey}.css`,
            type: 'text/css',
          },
        ],
      };
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),

    cssVariablesBinding() {
      return {
        ...this.cssVariables,
        '--c-primary': this.colors.primary,
        '--c-secondary': this.colors.secondary,
        '--c-border': this.colors.border,
        '--c-input_placeholder': this.colors.input_placeholder,
        '--c-text_white': this.colors.text_white,
        '--c-text_black': this.colors.text_black,

        /* Typography */
        '--f-primary': this.colors.primary_font,
        '--f-secondary': this.colors.secondary_font,

        /* neuron variable */
        '--checkbox-checked-color': this.colors.primary,
        '--white': this.colors.text_white,
        '--default-font': this.colors.primary_font,
        '--checkbox-tick-color': 'white',
        '--input--placeholder': this.colors.input_placeholder,
        '--nc-secondary-bg-color': this.colors.primary,
        '--seashell': this.colors.secondary,
        '--dark-brown': '#666666',
        '--default-border-color': this.colors.border,
        '--nc-popup-background-color': 'white',
        '--nc-property-status-bg-color': this.colors.primary,
        '--nc-property-status-text-color': 'white',
      };
    },
  },
});
</script>
